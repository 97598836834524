import React, { useContext, useLayoutEffect, useRef, useState } from 'react' 
import logo1 from '../assets/Logo.png'
import Navbar4 from '../component/Navbar4'
import {gsap, Power3 } from 'gsap'
import { useDraggable } from 'react-use-draggable-scroll'
import { CiMail } from 'react-icons/ci'
import { FaPhoneAlt } from 'react-icons/fa'
import { AiFillFacebook, AiFillInstagram} from 'react-icons/ai'
import {TfiLocationPin} from 'react-icons/tfi'
import { useQuery } from 'react-query'
import { baseImageUrl, getProjects } from '../api/api'
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi2'
import { GlobalContext } from '../App'

const Home2 = () => {

    const [onOpen, setOpen] = useState(false)

    const [sliderState,setSliderState] = useState(0)
    const slider = useRef(null)
    const projectDetailsRef = useRef(null)
    const {cursorRef} = useContext(GlobalContext)

    const handleNext = (e) => {
        if(sliderState === images.length - 1) {
            setSliderState(0)
            return
        }
        setSliderState(sliderState + 1)
    }

    const handlePrevious = (e) => {
        if(sliderState=== 0) {
            setSliderState(images.length - 1)
            return
        }
        setSliderState(sliderState - 1)
    }

    const handleMouseEnter = () =>{
        if(!onOpen){
            cursorRef.current.innerHTML = `<div class='parent-right'><div class='right-arrow-b'></div><div class="right-arrow"></div></div>`
        }
    }
   
    const handleMouseLeave = () => { 
            cursorRef.current.innerHTML = `` 
    }

    let timeline = new gsap.timeline();
    let ease = Power3.easeOut();

    const homeRef = useRef(null)
    const { events } = useDraggable(homeRef);

    function openHome(){
        timeline
            .to('.homepage',1,{
                display:'flex',
                ease:ease,
                opacity:1,
                margin:0
            })
    }

    useLayoutEffect(()=>{
        {onOpen && openHome()}
    },[onOpen])

    useLayoutEffect(()=>{
        timeline
            .to(projectDetailsRef.current,1,{ 
                marginLeft:0,
                opacity:1
            })
    },[])

    let {data,isLoading,isError,isFetching,isFetched,refetch} = useQuery('projetsAll',getProjects)
    console.log(data?.projects?.Items.slice(0,3))

    const images = [
        {url:'https://s3-mern-demo.s3.ap-south-1.amazonaws.com/sliderImages/PanvalaBunglow_cam_01.jpg'},
        {url:'https://s3-mern-demo.s3.ap-south-1.amazonaws.com/sliderImages/1.png'},
        {url:'https://s3-mern-demo.s3.ap-south-1.amazonaws.com/sliderImages/1.jpg'}
    ]

    const handleMouseMove = (e) => {
        const rightTrigger = window.innerWidth - window.innerWidth/2
        const leftTrigger = window.innerWidth/2
        if(rightTrigger < e.pageX && onOpen){ 
          cursorRef.current.innerHTML =  `<div class='parent-right'><div class='right-arrow-b'></div><div class="right-arrow"></div></div>`
    
        }else if(e.pageX < leftTrigger && onOpen){  
        cursorRef.current.innerHTML = `<div class='parent-left'><div class='left-arrow-b'></div><div class="right-arrow"></div></div>`
    
        }else{  
          if(!onOpen){
            cursorRef.current.innerHTML = `<div class='parent-right'><div class='right-arrow-b'></div><div class="right-arrow"></div></div>`
          }else{
            cursorRef.current.innerText = ''
          } 
        } 
      }

      const handleClickScrollLeft = (e) => { 
        const rightTrigger = window.innerWidth - window.innerWidth/2
        const leftTrigger = window.innerWidth/2
        let shift = homeRef.current.scrollLeft - 300 
        if(rightTrigger < e.pageX && onOpen){
          shift = homeRef.current.scrollLeft + 300
        }else if(e.pageX < leftTrigger && onOpen){
          shift = homeRef.current.scrollLeft - 300
        }
        homeRef.current.scrollTo({
          left: shift,
          behavior: 'smooth'
        });
      }

  return (
    <>
    <Navbar4 />
    <div className='bg-black px-10 pt-20' onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}> 
        <div className='cover-card   flex gap-4 relative text-white overflow-x-scroll noscroll' ref={homeRef} {...events} onClick={handleClickScrollLeft}>
            <div style={{width:'calc(100vh - 140px)'}} className='   h-full shrink-0 relative' >
               
                {images.map((slide,index)=>
                    <div className={`${index === sliderState ? 'slide active' : 'slide'} z-10`} key={index} >
                        {sliderState === index && <img src={slide.url} className=' w-full h-full object-cover cover-img' alt="" ref={el=> slider.current = el}/>}
                    </div>
                )}
                <div className='absolute w-full flex justify-between px-2 z-10 text-white bottom-2'>
                    <span className='border hover:border-white  border-transparent p-2' value={-1} onClick={(e)=>handlePrevious(e)}>
                        <HiArrowLeft size={20}/> 
                    </span>
                    <span className='border hover:border-white  border-transparent p-2' value={-1} onClick={(e)=>handleNext(e)}>
                        <HiArrowRight size={20}/> 
                    </span>
                </div>
            </div>
            <div className='uppercase flex flex-col w-screen mr-10 relative -ml-[500px] opacity-0' ref={projectDetailsRef} onClick={()=>setOpen(true)}>
                <img src={logo1} className='w-20 ' alt="" />
                <div className=' text-[40px] block w-fit whitespace-nowrap'>
                    <span>Prayosha </span>
                    <span className='font-semibold text-green-800'>Architects</span>
                </div>
                <p className='text-gray-300'>Surat</p>
                <p className='text-gray-300'>2016</p>
                <div className='h-full  w-full'      
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>

                </div>
                <div className='mt-auto mb-0  '>
                    <p className='  text-gray-400'>OUR SERVICES</p>
                    <p>Architecture • Landscape • Interior Design</p>
                </div>
            </div>

            <div className='w-full flex gap-6   shrink-0 ml-10 homepage hidden opacity-0 [&>div]:shrink-0'>
                <div className='w-[600px] h-full flex flex-col '>

                    <h1 className='text-xl font-semibold  uppercase font-poppins pb-2 '>About Us</h1>

                    <div className='h-full flex flex-col justify-around   px-10'> 
                        <div className=' '>
                            <div className='flex w-full justify-center [&>span]:mx-1'>
                                <span className='w-1 h-8 bg-green-800 inline-block skew-y-[45deg]'></span>
                                <span className='w-1 h-8 bg-green-800 inline-block skew-y-[45deg]'></span>
                                <h1 className='mx-1 font-Lora font-bold text-center text-2xl mb-2 text-green-800 '> PRAYOSHA</h1>
                                <span className='w-1 h-8 bg-green-800 inline-block skew-y-[45deg]'></span>
                                <span className='w-1 h-8 bg-green-800 inline-block skew-y-[45deg]'></span>
                            </div>
                            <p className='text-center font-robotoSlab mt-2 italic font-thin'>
                                The name "Prayosha" holds great significance as it is believed to be a blessed gift from our three enlightening gurus: Pramukhswami Maharaj, Yogiji Maharaj, and Shastriji Maharaj. I was fortunate enough to receive this name as a blessing from our revered leader, Mahantswami Maharaj.
                            </p> 
                        </div>
                        <div>
                            <h1 className='text-center uppercase text-lg  pb-2 font-base '>Our Philosophy</h1>
                            <p className='text-center font-robotoSlab'>
                                The name "PRAYOSHA" is derived from the word "p\v[S," which means "the entrance." This name symbolizes the introduction of new creative ideas, paths, spaces, and comfort, as well as a connection with nature.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='w-fit h-full flex flex-col '>

                    <h1 className='text-xl font-semibold  uppercase font-poppins pb-2'>Our Team</h1>

                    <div className='  team-img   '> 
                        
                       <img src="https://s3-mern-demo.s3.ap-south-1.amazonaws.com/5f3b86da-c8e9-402b-a30f-e79e03c230cf.JPG" className='w-full h-full object-cover' alt="" />      
                    </div>
                </div>
                <div className='w-fit h-full flex flex-col   px-2 '>
                    <h1 className='text-xl font-semibold  uppercase font-poppins pb-2'>Recent Works</h1>
                    <div className='flex gap-4'>
                    {data?.projects?.Items.slice(0,3).map((x)=>(
                         <div className='' key={x.id}>
                            <div className='  project-card '> 
                                <img src={`${baseImageUrl}/${x?.coverImg?.name}`}className='w-full h-full object-cover' alt="" />      
                            </div>
                            <div className='py-2 w-full flex  gap-4'>
                                <img src={`${baseImageUrl}/${x?.projectlogo?.name}`} className='w-12 h-12  invert  ' alt="" />
                                <div className='flex flex-col'>
                                    <span className='camelcase'>{x.name}</span>
                                    <span>{x.location}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                       
                        

                    </div>
                </div>
                <div className='w-[600px] h-full flex flex-col'>

                    <h1 className=' text-xl font-semibold  uppercase font-poppins pb-2'>Contact</h1>

                    <div className='h-full w-[450px] gap-6 flex flex-col items-center  justify-center px-2  
                        [&>div>h1]:uppercase 
                        [&>div>h1]:font-thin 
                        [&>div>h1]:text-xs
                        [&>div>h1]:mb-3
                        [&>div>h1]:mb-3'> 
                         
                         <div className=' flex flex-col w-full  h-full uppercase   justify-around  '>
                        <div className='flex flex-col items-start text-left gap-1 '>
                            <span className='mb-[0.5px] font-thin text-xs'>
                                address
                            </span>  

                            <span className='text-sm'>
                                603,White orchid, LP Savani Rd, Guru Ram Pavan Bhumi,Adajan Gam, Adajan, Surat, Gujarat 395009
                            </span>
                        </div>
                        <div className='flex flex-col items-start gap-1'> 
                            <span className=' font-thin text-xs'>
                                email
                            </span>  

                            <span className='text-sm lowercase'>
                                prayoshaarchitects@gmail.com
                            </span>
                        </div>
                        <div className='flex flex-col items-start gap-1'> 
                            <span className='font-thin text-xs' >
                               phone
                            </span>  
                        
                            <span className='text-sm'>
                            +91 9601651242
                            </span>
                        </div>
                        <div className='flex flex-col items-start gap-1'>
                            <span className='font-thin text-xs' >
                               Social
                            </span>  
                            <span className='flex'> <AiFillInstagram size={25}/> <AiFillFacebook size={25}/> </span> 
                        </div>
                    </div>
                        
                        
                         
                        
                    </div>
                </div>
            </div>
        </div> 
    </div>
    </>
  )
}

export default Home2
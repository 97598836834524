import {
  createContext,
  lazy,
  Suspense, 
  useRef,
  useState,
} from "react"; 
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom"; 
import { gsap, Power3 } from "gsap"; 
import Cursor from "./component/Cursor";   
import Loading from "./pages/Loading";
import LogoLoader from "./pages/LogoLoader";
import Home1 from "./pages/Home1";
import Home2 from "./pages/Home2";
import Home3 from "./pages/Home3";
import { HiArrowUp } from "react-icons/hi2";

export const GSAPContext = createContext();
export const GlobalContext = createContext()

function App() {
  const [isArrow,setIsArrow] = useState(false)
  const [downArrow,setDownArrow] = useState(false)
  let timeline = new gsap.timeline();
  let ease = Power3.easeOut(); 
  const cursorRef = useRef()
  
  const ProjectAll = lazy(()=>import('./component/ProjectAll'))
  const ProjectType = lazy(()=>import('./pages/ProjectType'))
  const ProjectHousing = lazy(()=>import('./component/ProjectHousing'))
  const ProjectInstitute = lazy(()=>import('./component/ProjectInstitute'))
  const ProjectCommercial = lazy(()=>import('./component/ProjectCommercial'))
  const AboutUs = lazy(()=>import('./pages/AboutUs'))
  const Contact = lazy(()=>import('./pages/Contact'))
  const Projects = lazy(()=>import('./pages/Projects'))

  const handleScrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <GSAPContext.Provider value={{ timeline, ease }}>
      <GlobalContext.Provider value={{isArrow,setIsArrow,downArrow,setDownArrow,cursorRef}}>
        <div className="min-h-screen min-w-screen relative bg-black "  >
          <BrowserRouter>
          <Suspense fallback={<Loading/>}>
            <Routes>
              <Route path="/" element={
                <>
                  <div className=" sm:hidden">
                    <Home3/>
                  </div>
                  <div className="hidden sm:block">
                    <Home2 />
                  </div>
                </>
              } />
              <Route path="/home1" element={<Home1 />} />
              <Route path="/home2" element={<Home2 />} />

              <Route path="/projects" element={<Projects />} >
                <Route index element={<ProjectAll/>}/>  
                <Route path=":id" element={<ProjectType/>}/>   
              </Route> 

              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/loading" element={<Loading />} />
              <Route path="/logoloader" element={<LogoLoader />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </Suspense>

          </BrowserRouter>
          {/* <Footer /> */}
        </div>
        <Cursor  cursorRef={cursorRef}/>
        <button className="font-bold fixed bottom-10 right-10  z-50  p-2" onClick={handleScrollToTop}><HiArrowUp/></button>
      </GlobalContext.Provider>
    </GSAPContext.Provider>
  );
}

export default App;

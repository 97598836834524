import React, { useLayoutEffect, useRef, useState } from 'react'  
import Navbar4 from '../component/Navbar4'
import {gsap, Power3 } from 'gsap'
import { useDraggable } from 'react-use-draggable-scroll'
import { CiMail } from 'react-icons/ci'
import { FaPhoneAlt } from 'react-icons/fa'
import { AiFillFacebook, AiFillInstagram, AiOutlineArrowRight} from 'react-icons/ai'
import {TfiLocationPin} from 'react-icons/tfi'
import { useQuery } from 'react-query'
import { baseImageUrl, getProjects } from '../api/api'
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi2'
import logo1 from '../assets/Logo.png'
import { BsDot } from 'react-icons/bs'
import { MdArrowForwardIos } from 'react-icons/md'
import { Link } from 'react-router-dom'
const Home3 = () => {

    const [onOpen, setOpen] = useState(false)

    const [sliderState,setSliderState] = useState(0)
    const slider = useRef(null)
    const projectDetailsRef = useRef(null)

 
    
    const handleNext = (e) => {
        if(sliderState === images.length - 1) {
            setSliderState(0)
            return
        }
        setSliderState(sliderState + 1)
    }

    const handlePrevious = (e) => {
        if(sliderState=== 0) {
            setSliderState(images.length - 1)
            return
        }
        setSliderState(sliderState - 1)
    }

   
   

    let timeline = new gsap.timeline();
    let ease = Power3.easeOut();

    const homeRef = useRef(null)
    const { events } = useDraggable(homeRef);

    function openHome(){
        timeline
            .to('.homepage',1,{
                display:'flex',
                ease:ease,
                opacity:1,
                margin:0
            })
    }

    useLayoutEffect(()=>{
        {onOpen && openHome()}
    },[onOpen])

    useLayoutEffect(()=>{
        timeline
            .to(projectDetailsRef.current,1,{ 
                marginLeft:0,
                opacity:1
            })
    },[])

    let {data,isLoading,isError,isFetching,isFetched,refetch} = useQuery('projetsAll',getProjects)
    console.log(data?.projects?.Items.slice(0,3))

    const images = [
        {url:'https://s3-mern-demo.s3.ap-south-1.amazonaws.com/sliderImages/PanvalaBunglow_cam_01.jpg'},
        {url:'https://s3-mern-demo.s3.ap-south-1.amazonaws.com/sliderImages/1.png'},
        {url:'https://s3-mern-demo.s3.ap-south-1.amazonaws.com/sliderImages/1.jpg'}
    ]

  return (
    <>
    <Navbar4 />
    <div className='  w-full   py-20' > 
        <div className=' flex gap-4 relative text-white flex flex-col ' ref={homeRef}  >
          
            <div className='w-[90vw]   mx-auto h-[90vw] shrink-0 relative '> 
                {images.map((slide,index)=>
                    <div className={`${index === sliderState ? 'slide active' : 'slide'} z-10 w-full h-[90vw]`} key={index} >
                        {sliderState === index && <img src={slide.url} className=' w-full h-full object-cover  ' alt="" ref={el=> slider.current = el}/>}
                    </div>
                )}
                <div className='absolute w-full flex justify-between px-2 z-10 text-white bottom-2'>
                    <span className='border    border-transparent p-2' value={-1} onClick={(e)=>handlePrevious(e)}>
                        <HiArrowLeft size={20}/> 
                    </span>
                    <span className='border    border-transparent p-2' value={-1} onClick={(e)=>handleNext(e)}>
                        <HiArrowRight size={20}/> 
                    </span>
                </div>
            </div>
            <div className=' w-[90vw] mx-auto flex justify-between   '>
                <img src={logo1} alt=""  className='w-[80px] shrink-0 h-[80px]'/>
                <div className='flex flex-col text-[24px] block w-fit uppercase -mt-2'>
                    <div>
                        <span className='font-base'>Prayosha </span>
                        <span className='font-base text-green-800'>Architects</span>
                    </div>
                    <div className='text-lg font-thin'>
                        Surat
                    </div>
                </div>
            </div>
            <button className='text-white uppercase absolute top-[70vh] text-lg right-[5vw] flex items-center gap-2  px-2 py-1 rounded-full'>      <Link to={'/projects'}>Explore </Link> 
                <MdArrowForwardIos/>
            </button>
            <div className='h-[40vw] w-full '></div>
            
            <div className='w-[90vw] h-[90vh] mx-auto flex flex-col items-center justify-center gap-10'>
                <div className='flex w-full items-center justify-center [&>span]:mx-1'>
                    <span className='w-1 h-10 bg-green-800 inline-block skew-y-[45deg]'></span>
                    <span className='w-1 h-10 bg-green-800 inline-block skew-y-[45deg]'></span>
                    <h1 className='mx-2 font-Lora font-bold text-center text-4xl mb-2 text-green-800 '> PRAYOSHA</h1>
                    <span className='w-1 h-10 bg-green-800 inline-block skew-y-[45deg]'></span>
                    <span className='w-1 h-10 bg-green-800 inline-block skew-y-[45deg]'></span>
                </div>
                <p className='text-center font-robotoSlab italic'>
                    The name "PRAYOSHA" is derived from the word "p\v[S," which means "the entrance." This name symbolizes the introduction of new creative ideas, paths, spaces, and comfort, as well as a connection with nature.
                </p>
            </div>
            <div className='w-[90vw] mx-auto'>
                <h1 className='pb-2'>OUR TEAM</h1>
                <div className='flex flex-col gap-2'>
                    <div className='w-full h-[90vw] bg-slate-300'>
                        <img src="https://s3-mern-demo.s3.ap-south-1.amazonaws.com/5f3b86da-c8e9-402b-a30f-e79e03c230cf.JPG" className='w-full h-full object-cover' alt="" /> 
                    </div>
                </div>
            </div>
            <div className='w-[90vw] mx-auto'>
                <h1 className='pb-2'>RECENT WORKS</h1>
                <div className='flex flex-col gap-2'>
                    <div className='w-full h-[90vw] bg-slate-300'>
                         
                    </div>
                </div>
            </div>
            <div className='w-[90vw] mx-auto px-2'>
                <h1 className='pb-2'>Contact</h1>
                <div className='flex flex-col gap-2 '>
                    <div className=' flex flex-col w-full h-[90vw] uppercase   justify-around  '>
                        <div className='flex flex-col items-start text-left gap-1 '>
                            <span className='mb-[0.5px] font-thin text-xs'>
                                address
                            </span>  

                            <span className='text-sm'>
                                603,White orchid, LP Savani Rd, Guru Ram Pavan Bhumi,Adajan Gam, Adajan, Surat, Gujarat 395009
                            </span>
                        </div>
                        <div className='flex flex-col items-start gap-1'> 
                            <span className=' font-thin text-xs'>
                                email
                            </span>  

                            <span className='text-sm'>
                                prayoshaarchitects@gmail.com
                            </span>
                        </div>
                        <div className='flex flex-col items-start gap-1'> 
                            <span className='font-thin text-xs' >
                               phone
                            </span>  
                        
                            <span className='text-sm'>
                            +91 9601651242
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
    </>
  )
}

export default Home3